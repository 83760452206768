import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f6ccaf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topNav" }
const _hoisted_2 = { class: "search-box" }
const _hoisted_3 = { class: "datePicker" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_option_group = _resolveComponent("el-option-group")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_select, {
            placeholder: "請選擇操作",
            class: "actions-select",
            modelValue: _ctx.resource,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resource) = $event)),
            filterable: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resourceOptions, (group) => {
                return (_openBlock(), _createBlock(_component_el_option_group, {
                  key: group.label,
                  label: group.label
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_date_picker, {
              class: "datePicker",
              modelValue: _ctx.dateRange,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateRange) = $event)),
              type: "daterange",
              "range-separator": "-",
              "start-placeholder": "開始時間",
              "end-placeholder": "結束時間",
              onChange: _ctx.handleDateChange
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _createVNode(_component_SearchBox, {
            placeholder: "操作員名稱 / 帳號",
            clickSearch: (_keyword) => _ctx.keyword = _keyword,
            inputWidth: "220px",
            keyword: _ctx.keyword
          }, null, 8, ["clickSearch", "keyword"]),
          _createVNode(_component_Filter, {
            options: _ctx.filterOptions,
            onChange: _ctx.handleFilterChange
          }, null, 8, ["options", "onChange"])
        ])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        "empty-text": "暫無資料"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "操作時間"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.formatLocalTime(row.createdAt) ? _ctx.formatLocalTime(row.createdAt, 'YYYY-MM-DD HH:mm:ss') : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "操作行為"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.action), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "操作項目"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("div", {
                class: _normalizeClass({'text-left': !row.item || row.item !== '-'}),
                innerHTML: row.item.replace(/\n/g, '<br>') || '-'
              }, null, 10, _hoisted_4)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "操作結果"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("div", {
                class: _normalizeClass({'text-left': !row.result || row.result !== '-'}),
                innerHTML: row.result.replace(/\n/g, '<br>') || '-'
              }, null, 10, _hoisted_5)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            "class-name": "status-col",
            label: "操作員"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.operatorName || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "操作員 IP",
            prop: "createdAt"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.operatorIP || '-'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.pageSize,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total,
        onCurrentChange: _ctx.setPageQuery
      }, null, 8, ["currentPage", "page-size", "total", "onCurrentChange"])
    ]),
    _: 1
  }))
}