
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useOperationLogs } from '@/composables/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { DEFAULT_PAGE_SIZE, getOperationResources } from '@/services/api';
import { OperationResource } from '@/interfaces';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useKeyword, usePage } from '@/composables';

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const dateRange = ref(['']);
    const startDate = ref('');
    const endDate = ref('');
    const resource = ref('');
    const resources = ref<OperationResource[]>([]);
    const resourceOptions = ref([]);
    const action = ref('');
    const actionOptions = ref([]);

    const { data, isLoading, isFetching } = useOperationLogs({
      page,
      keyword,
      pageSize,
      startDate,
      endDate,
      resource,
      action
    });

    const fetchResources = async() => {
      resources.value = (await getOperationResources({ query: {} })).data;
      // 選單分組顯示
      resources.value.forEach((group) => {
        resourceOptions.value[group.group] = {
          label: group.group,
          options: (() => {
            const optionList = [];
            resources.value.forEach((option) => {
              if (option.group === group.group) {
                optionList.push({
                  label: option.name,
                  value: option.id
                });
              }
            });
            return optionList;
          })()
        };
      });
      resourceOptions.value = Object.values(resourceOptions.value);
      // 選單預設是 操作員登入歷史
      resource.value = route.query.resource || resourceOptions.value?.[0]?.options?.[0].value || '';
    };

    const handleFilterChange = (event: FilterEvent) => {
      action.value = event[0];
    };

    const handleDateChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }
      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    onMounted(() => {
      fetchResources();
    });

    watch(resource, () => {
      action.value = '';
      actionOptions.value = resources.value.filter(e => String(e.id) === resource.value)?.[0]?.actions.map(({ name, id }) => {
        return ({ label: `${name}`, value: `${id}` });
      }) || [];
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '操作行為',
          placeholder: '請選擇',
          options: actionOptions.value
        }
      ];
    });

    watch([resource, startDate, endDate], ([resource, startDate, endDate]) => {
      router.push({ query: { ...route.query, resource, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        resource.value = query.resource as string;
        startDate.value = query.startDate as string;
        endDate.value = query.endDate as string;

        if (startDate.value && endDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      isLoading,
      isFetching,
      filterOptions,
      dateRange,
      resource,
      resources,
      resourceOptions,
      action,
      actionOptions,
      keyword,
      setPageQuery,
      formatLocalTime,
      handleDateChange,
      handleFilterChange
    };
  }
});
